import { DOMAIN_BETA, DOMAIN_DEV, DOMAIN_PROD, DOMAIN_PROD_MANAGER, DOMAIN_PROD_PARTNER } from ".";
import { isClient } from "../../utils";

const originCode = {
  [DOMAIN_PROD]: "G-VLZCZXXJSB",
  [DOMAIN_PROD_PARTNER]: "G-GN0XHEQGBT",
  [DOMAIN_PROD_MANAGER]: "G-1K2LBE78BY",
  [DOMAIN_DEV]: "G-3QT8PK751H", // 和app 合并数据流
  [DOMAIN_BETA]: "G-EKK4QWXEZK",
  ["localhost"]: "G-EKK4QWXEZK"
};

const apikeyGa = isClient && originCode[location.hostname];
export default apikeyGa ?? "";
