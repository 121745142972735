import { IEnvConfig } from "@aspen/model";
import { apikeyBugsnagProd } from "../index";

const staticHost = "https://static.aspendigital.co/";
export const preConfig: IEnvConfig = {
  env: "pre",
  host: {
    dev: "https://api2.aspendigital.co/",
    prod: "https://api2.aspendigital.co/"
  },
  klineChartUrl: `${staticHost}klinechart/index.html`,
  tvChartUrl: "https://tradingview-nextjs-javascript-git-green-aspendigital.vercel.app",
  websocket: {
    host: "wss://ws.aspendigital.co:8080"
  },
  apiKeyBugsnag: apikeyBugsnagProd,
  webDomain: "https://green-portal.aspendigital.co",
  domainUriPrefix: "https://preaspendigital.page.link",
  fallbackUrl: {
    android:
      "https://install.appcenter.ms/orgs/kikitrade_ios/apps/aspen_android_prod_pre/distribution_groups/prod-pre/releases/36",
    ios: "https://install.appcenter.ms/orgs/kikitrade_ios/apps/aspen_ios_prod_pre/distribution_groups/prod-pre/releases/34"
  },
  packageName: {
    android: "com.pre.aspen",
    ios: "org.evg.pre.aspen"
  },
  minimumVersion: {
    android: "50",
    ios: "60"
  },
  operationServer: "https://api.aoperation.aspendigital.co/",
  saasServer: "https://api.dev-asportal.co/"
};
