// 排除错误上报的code集合
export const ERROR_CODE = {
  "-1": "customer.not.login",
  "1": "Invalid username or password",
  "-3": "agent.operation.is.not.authorized"
};

// 错误上报的msg集合 (待和后端沟通，调整返回message) https://app.bugsnag.com/aspen-web-prod/web-prod-aspendigital/errors/62871bc1c8b31b0008e9d79a?filters[event.since]=30d&filters[error.status]=new
export const ERROR_MSG = ["has not registered"];

// 错误上报可忽略的msg集合
export const IGNORE_BUGSNAG_ERROR = [
  "ResizeObserver loop limit exceeded", // antd select组件触发，官方暂时也是忽略该错误
  "ResizeObserver loop completed with undelivered notifications",
  "getBoundingClientRect", // TypeError
  "unhandledrejection handler", // 未处理的promise错误， 当promise被reject并且错误信息没有被处理的时候，会抛出一个unhandledrejection
  "Request aborted", // 请求取消
  "customer.email.code.incorrect", // 邮箱验证码错误
  "customer.google.auth.fail" // 谷歌验证码错误
];
