import useSWR, { useSWRConfig } from "swr";
import { ISWRConfig } from "@aspen/model/index";
import { CACHE_DEFAULT_OPTIONS, isClient } from "@aspen/libs/index";
// import { SWRResponse } from "swr/_internal";
// import useSWRMutation from 'swr/mutation'
/**
 * @description 重用数据
 * @param {string} path 请求路径
 * @param {()=>void} fetcher fetcher 函数
 * 请求有 3 种可能的状态：loading、ready或 error,
 * 返回data、error 、 isLoading、isValidating, mutate, 参考：https://swr.vercel.app/zh-CN/docs/api.zh-CN#return-values
 * data: 通过 fetcher 用给定的 key 获取的数据（如未完全加载，返回 undefined）
 * error: fetcher 抛出的错误（或者是 undefined）
 * isLoading: 是否有一个正在进行中的请求且当前没有“已加载的数据“。预设数据及之前的数据不会被视为“已加载的数据“
 * isValidating: 表示每次正在进行的重新请求或验证加载
 * mutate(data?, options?): 更改缓存数据的函数 （详情）
 *
 */

function useSWRData<T>(config: ISWRConfig) {
  const { key, fetcher, options } = config;
  const { cache } = useSWRConfig();
  // 切换路由的时候可以从cache拿到缓存数据
  let cacheData: any = cache.get(key);
  // 缓存数据存在，刷新页面时swr拿不到，故手动取下缓存数据，
  if (isClient) {
    cacheData = localStorage.getItem(key.toString()) as string;
  }
  const fallbackDataOptions = {
    // 初始数据
    fallbackData: cacheData != undefined ? JSON.parse(cacheData) : undefined
  };
  // useSWR 根据 fetcher 函数的状态返回 data，error，isLoading 和 isValidating
  const allOptions = { ...CACHE_DEFAULT_OPTIONS, ...options, ...fallbackDataOptions };

  const { data, error, isLoading, isValidating, mutate } = useSWR<T>(key, fetcher, allOptions);
  console.log(
    "SWR res key: \n",
    `${key}\n`,
    "data:",
    data ? JSON.stringify("has data") : null,
    "isLoading:",
    isLoading,
    "isValidating:",
    isValidating
  );

  if (isClient && data) {
    try {
      // 缓存数据
      localStorage.setItem(key.toString(), JSON.stringify(data));
    } catch (error) {
      console.log("Failed to execute 'setItem' on 'Storage'");
    }
  }

  // any不修改，返回的数据类型不确定，详见swr源码
  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate
  };
}

export { useSWRData };
